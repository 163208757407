.chat-sponsor{
    background-color: aqua;
    width: 30%;
}
.logo-sponsor{
    width: 100%;
    height: 15vh;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    cursor: pointer;
}
.logo-sponsor a{
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
}
.logo-sponsor a img{
    margin: 0;
    width: 95% !important;
}
.video-sponsor{
    width: 100% !important;
    
}
.join-sponsor{
    background-color: var(--social_bg);
    border: none;
    height: 40px;
    width: 40%;
    border-radius: 10px;
    color: var(--header_bg);
    font-weight: 700;
    cursor: pointer;
}
.joi{
    background-color: var(--header_bg);
    display: flex;
    justify-content: center;
}