.title-color{
    width: 100%;
}
.box-countdown{
    width: 100%;
    margin-top: 10px;
    border: 1px solid #fff;
    border-radius: 10px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;

    color: #fff;
}