.marginTop {
  margin-top: 25px;
  width: 100%;
  border-radius: 7px;
  height: auto;
  overflow: hidden;
}
.miniCams {
  width: 100%;
  height: 150px;
  position: relative;
}
.vid-minicams{
  width: 100%;
  height: calc(100% - 30px);
}
.minicams-name{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
}
.vid-minicams-local{
  width: 100%;
  height: 30vh;
  position: relative;
}
.vid-minicams-localscreen{
  width: 100%;
  height: calc(100% - 30px);
}