.main-bg2 {
  margin: auto;
  padding-top: 80px;
}

.logo-agenda2 {
  padding: 25px;
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}
.card-home{
  padding: 0 25px;
  border: #fff solid 3px;
  border-radius: 25px;
  background-color: #fff;
}
.card-home:hover{
  background-color: rgba(255, 255, 255, 0.527);
}
a{
  text-decoration: none;
}
h1.event-title{
  color: black;
}
.logo-content{
  width: 100%;
}
@media screen and (min-width: 300px) and (max-width: 720px) {
  .flex-box {
    display: block;
  }
  .logo-agenda {
    width: 93%;
    padding: 25px;
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 25px;
    justify-content: center;
  }
  .grid2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .date-box{
      width: 100%;
      margin-top: 25px;
      padding: 10px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 25px;
      border-top-right-radius: 0;
  }
  .box{
      padding-bottom: 80px;
  }
}
