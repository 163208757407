.list-infoSpeaker ul {
  margin-left: -45px;
  list-style: none;
  color: #fff;
  display: flex;
  position: relative;
}
.list-infoSpeaker ul li {
  list-style: none;
  color: #fff;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 10px;
}
.list-infoSpeaker ul li:nth-child(2n) {
  background-color: transparent;
}
.space-infoSpeaker {
  overflow: hidden;
  margin-top: -25px;
  background-color: var(--header_bg);
  width: 93%;
  height: auto;
  max-height: 500px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}
.space-infoSpeaker h1 {
  margin-top: 15px;
  color: #fff;
}
.container-speaker {
  display: flex;
  align-items: center;
  gap: 25px;
}
.container-speaker img {
  border-radius: 100%;
}
.container-speaker div h2 {
  color: var(--blue_light);
  margin-top: -15px;
  min-inline-size: 5px;
}
.resource {
  width: 90%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  padding: 5px;
  color: #fff;
}
.resource h4 {
  margin: 0;
}

.resource:hover {
  background-color: rgba(253, 253, 253, 0.2);
  cursor: pointer;
}
.data-information{
  margin: auto;
  height: 90%;
  overflow-y: scroll;
}
.data-information::-webkit-scrollbar{
  width: 7px;
  background-color: rgba(253, 253, 253, 0.2);
}
.data-information::-webkit-scrollbar-thumb{
  width: 7px;
  background-color: #fff;
  border-radius: 15px;
}
.data-info {
  width: 100%;
  color: #fff;
  font-size: 0.8vw;
  text-transform: none;
  line-height: 115%;
}
.team {
  height: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
}
.team::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.15);
}
.team::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 7px;
  background-color: #fff;
}
.img-team {
  width: 100px !important;
  height: 100px !important;
  border-radius: 150px;
}
.img-team img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}
.flex-team {
  display: flex;
  gap: 15px;
  color: #fff;
}
.grid-resource {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
}
.grid-resource::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.15);
}
.grid-resource::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}
.buttons {
  display: flex;
  gap: 10px;
}
.buttons button {
  display: flex;
  gap: 10px;
  background-color: var(--social_bg);
  border: none;
  border-radius: 10px;
  color: var(--header_bg);
  font-weight: 700;
  padding: 0 10px;
  cursor: pointer;
}
.photo-team-speakers {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.videoMain-2 {
  width: 70%;
}
@media screen and (max-width: 425px) {
  .lista-selects {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .lista-selects li:nth-child(2),
  li:nth-child(4),
  li:nth-child(6) {
    display: none;
  }

  .space-infoSpeaker {
    width: 86%;
  }
  .videoMain-2 {
    width: 100%;
  }
  
  
  .data-info{
      font-size: 14px !important;
  }
  .grid-resource{
      grid-template-columns: 1fr;
  }
  .team{
      width: 100%;
      grid-template-columns: 1fr;
      overflow-x: hidden;
  }
  .flex-team{
      width: 80%;
      display: flex;
      align-items: center;
  }
  .img-team img{
      width: 80px !important;
      height: 80px !important;
  }
}
