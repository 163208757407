.chat-2 {
  width: 20%;
}
.chat {
  width: 30%;
}
.ligth-box{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  background-color: rgba(24, 23, 23, 0.5);
}
.container-chat{
  position: relative;
  width: 70%;
  padding: 0 15px;
  height: auto;
  border-radius: 10px;
  background-color: #2b285f;
}
.close-chat{
  background-color: #12133f;
  border: none;
  color: white;
  font-weight: 500;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.chat-mobile {
  width: 100%;
}
.border{
  border: 0.1px solid #fff !important;
}
.chat-list {
  margin-left: -50px;
  display: flex;
  list-style: none;
}
.chat-list li {
  background-color: var(--header_bg);
  color: #fff;
  border-radius: 10px;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  list-style: none;
  cursor: pointer;
}
.chat-list li:nth-child(2) {
  background-color: transparent;
  color: #fff;
}
.messages {
  height: 30vh;
  position: relative;
  z-index: 1;
  margin-top: -25px;
  padding: 10px;
  color: #fff;
  background-color: #12133f;
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.scroll {
  scrollbar-width: thin;
}
.messages {
  position: relative;
}
.messages form {
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.messages form textarea {
  border-radius: 5px;
  background-color: #2b285f;
  color: #fff !important;
  width: 100%;
  height: 37px;
  border: none;
}

.messages-container {
  position: relative;
  height: calc(30vh - 40px);
  overflow-y: scroll;
}
.messages-container::-webkit-scrollbar {
  width: 7px;
  height: 100%;
}
.messages-container::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 25px;
}
.message-person {
  color: var(--social_bg);
}
.conexion {
  display: flex;
  gap: 15px;
  padding: 7px;
  align-items: center;
}
.circle-conexion {
  width: 10px;
  height: 10px;
  background-color: var(--social_bg);
  border-radius: 40px;
}
.hands {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #46b2ce;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hands:hover .tooltip-chat {
  display: block;
}

.tooltip-chat {
  display: none;
  width: 120px;
  position: absolute;
  top: 35px;
  left: -100px;
  background-color: #fff;
  color: #12133f;
  border-radius: 5px;
  padding: 3px;
  font-size: 11px;
  z-index: 9;
  text-align: center;
}
.tooltip-chat::after {
  content: "";
  position: absolute;
  top: -5px;
  right: 5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}
.logo-sponsor {
  height: 20vh !important;
  margin-top: 10px;
  border-radius: 10px !important;
  overflow: hidden;
}
.img-sponsor {
  width: 95%;
}
.color-red {
  background-color: red;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .chat {
    width: 100%;
  }
}
