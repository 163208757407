.Dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
}
.Dropdown-content {
  width: 100%;
  position: absolute;
  display: none;
  top: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.Dropdown:hover .Dropdown-content {
  display: block;
}
.Dropdown-content ul {
  list-style: none;
  color: black;
}
.Dropdown-content ul li {
  width: 100%;
  margin: 5px;
}
.Dropdown-content ul li:hover {
  background-color: var(--box_date_bg);
}
.flex-s {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowDown-left {
  margin-left: 10px;
}
