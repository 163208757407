/* palette */
:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;

  --blue_light: #46b2ce;
  --light: #fff;

  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;

  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;
}

.agendaMobile {
  margin-top: 50px !important;
  margin: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spaceMovile {
  height: 100vh;
}
.agendaSpeakers {
  background-color: var(--speakers_bg);
  color: var(--light);
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.4rem;
}

.space {
  padding-top: 25px !important;
  height: auto;
  background-color: #12133f;
  border-top-right-radius: 10px;
  position: relative;
}
.grid {
  width: 100% !important;
}
.list-agenda {
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: start;
  list-style: none;
  margin-top: -1px;
  margin-bottom: -5px;
}
.m5px {
  padding: 10px;
}
.list-agenda li {
  margin: 0 10px;
  padding: 10px;
}
.list-agenda {
  margin-left: -50px;
}
.list-agenda li:nth-child(2n + 1) {
  background-color: #12133f;
  cursor: pointer;
  border-radius: 10px;
}

.active {
  background-color: #12133f !important;
  font-size: 0.8em !important;
}
.disabled {
  background-color: transparent !important;
  font-size: 0.8em !important;
}
.disabled:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.disable {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

/* styles component form */

.formRegistroDesktop {
  height: 95% !important;
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.form-grid {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  color: #fff;
  font-weight: 500;
  font-size: calc(1.1rem);
  gap: 20px;
}
.form-grid li {
  display: flex;
  justify-content: space-between;
}
.form-grid li label {
  width: 30%;
}
.form-grid li input {
  width: 70%;
  height: 30px;
  color: #0b0b3e;
  border-radius: 10px;
  border: none;
  padding-left: 15px;
}
.form-select {
  width: 70%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding-left: 15px;
}
.formTitle {
  color: var(--social_bg);
  font-size: calc(1.3rem + 0.6vw);
}
.form-register {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.check-input {
  color: #fff;
  margin: auto;
  margin-bottom: 5px;
}
.btn-submit {
  display: block;
  margin: auto;
  background-color: var(--social_bg);
  height: 30px;
  width: 30%;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: #12133f;
  font-size: 1rem;
  font-weight: 500;
}
.logo {
  width: 70px !important;
  height: 70px;
  margin-right: 15px;
  border-radius: 100%;
}

/* ----styles component schedule----- */
.schedule {
  display: block;
  width: 95%;
  margin: 0 auto;
  padding-top: 25px;
}
.list-schedule {
  width: 100% !important;
  color: #fff;
  display: grid;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  gap: 10px;
  list-style: none;
  font-size: 13px;
  border-radius: 10px;
}
.list-schedule:hover {
  background-color: rgba(255, 255, 255, 0.123);
}

.list-schedule h2 {
  font-size: calc(0.2rem + 1vw);
}

.list-schedule li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.list-schedule li p {
  font-size: 1.2vw;
  line-height: 95%;
}
.list-schedule li:nth-child(1) {
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #fff;
}
.list-schedule li:nth-child(1) h2 {
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;
  font-size: 1.7vw !important;
  line-height: 90%;
}
.list-schedule li:nth-child(2) h1 {
  font-size: 1.7vw !important;
  line-height: 90%;
}
.list-schedule li:nth-child(2) {
  text-transform: uppercase;
  justify-content: flex-start;
  flex-direction: column;
}
.list-schedule li:nth-child(3) {
  align-items: flex-start;
  justify-content: flex-start;
}
.list-schedule li:nth-child(4) {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  display: flex;
  justify-content: end;
}
.list-schedule li h3 {
  text-align: center;
  width: 100%;
}
.list-schedule li:nth-child(1) {
  border-left: none;
}
.list-schedule li h1 {
  font-size: 20px;
}
.title-schedule {
  color: #fff;
}
.dropdown-schedule {
  width: 150px;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  color: black;
  padding: 5px;
  border-radius: 7px;
}
.image-dropdown {
  width: 12px;
  margin-left: 7px;
}
.list-dropdown {
  list-style: none;
  padding: 5px;
  border-radius: 10px;
}
.list-dropdown:hover {
  background-color: #46b2ce;
  color: #fff;
}
/* ----styles component speakers----- */

.title-speakers {
  color: #fff !important;
  font-size: 2rem;
}
.circle {
  width: 6rem;
  height: auto;
  border-radius: 100%;
}
.list-speaker {
  width: 100%;
}
.grip-speaker {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grip-speaker a {
  display: flex;
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  justify-content: start;
  align-items: center;
  border-radius: 25px;
}
.grip-speaker a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.2);
}
.flex-speaker {
  margin-top: -10px;
  height: 100px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.photo-speaker-div {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  margin-right: 25px !important;
}
.photo-speaker {
  margin-top: 15%;
  width: 100%;
  height: auto;
}
.speaker-name p {
  line-height: 100%;
}
.speaker-name p:nth-child(1) {
  font-size: 1.3vw;
  text-transform: uppercase;
}
/* style popup register */
.light-box-register {
  margin-left: -8%;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.popup-register {
  width: 40%;
  border-radius: 10px;
  height: 300px;
  background-color: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  text-align: center;
  flex-direction: column;
}
.popup-register button {
  width: 100px;
  height: 40px;
  color: #0b0b3e;
  font-weight: 700;
}
/* ----styles component sponsors----- */
.sponsor {
  width: 100% !important;
  margin-left: 15px;
}

.grid-gold {
  margin-top: 25px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 10px;
}
.grid-silver {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.box-sponsor-silver {
  width: 30%;
  height: 18vh;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.box-sponsor-gold {
  width: 45%;
  height: 25vh;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

.box-sponsor-gold figure {
  width: 95%;
  height: 95%;
  position: absolute;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.human {
  width: 40px;
}
/* styles statistics */
.statistics {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.statistics-item {
  padding: 5px;
  width: 90%;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.statistics-item-head {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.statistics-items-title {
  width: 100%;
  color: #fff;
}
.text-date {
  color: #fff;
  font-weight: 700;
}
.statistics-sponsor {
  background-color: #212529;
  text-align: center;
}

/* style networking */
.networking {
  width: 90%;
  height: 40vh;
  overflow-y: scroll;

  margin: auto;
  padding-top: 25px;
  padding-right: 5px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.networking::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.15);
}
.networking::-webkit-scrollbar-thumb {
  width: 7px;
  border-radius: 5px;
  background-color: #fff;
}

.button-network {
  width: 50%;
  height: 30px !important;
  background-color: #bedf35;
  border-radius: 10px;
  font-size: 1em;
  padding: 5px;
  color: var(--header_bg);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.alert-red {
  width: auto;
  background-color: #ff0000;
  height: 10px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px;
}
.box-network {
  max-height: 220px;
  color: #fff;
  padding: 7px;
  background-color: #26235f;
  border-radius: 10px;
}
.network-stn1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.network-stn2 {
  margin: 20px auto;
  max-width: 180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.network-m15 {
  margin: 20px auto;
  max-width: 240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.human-online-mesa12 {
  margin: 0;
  background-color: #fff;
  padding: 0;
  width: 30px;
  height: 30px;
}
.human-online-mesa12-disable {
  margin: 0;
  background-color: #414e9c;
  padding: 0;
  width: 30px;
  height: 30px;
}
.human-online-mesa-circle {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 100px;
  position: relative;
  top: 5px;
}
.human-online-mesa-circle-disable {
  width: 30px;
  height: 30px;
  background-color: #414e9c;
  border-radius: 100px;
  position: relative;
  top: 5px;
}
.human-online-space {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
}
.human-online {
  position: relative;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
}
.tooltip-network {
  position: absolute;
  z-index: 999;
  width: 100px;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
  background-color: #fff;
  color: #0b0b3e;
  border-radius: 7px;
  display: none;
}
.human-online:hover .tooltip-network {
  display: block;
  cursor: none;
}
.giro180 {
  transform: rotate(180deg);
}
.giro45 {
  transform: rotate(270deg);
}
.giro80 {
  transform: rotate(130deg);
}
.giro90 {
  transform: rotate(90deg);
}
.giro230 {
  transform: rotate(230deg);
}
.network-stn3 {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.network-stn3 h6,
h3 {
  margin: 0;
  padding: 0;
}
.light-popup-network {
  width: 90%;
  height: 80%;
  z-index: 99;
  background-color: #12133f71;
  position: absolute;
}
.popup-network {
  margin: 4% auto;
  padding: 10px;
  background-color: var(--header_bg);
  border-radius: 10px;
  box-shadow: 2px 1px 2px 1px rgba(255, 255, 255, 0.5);
  width: 300px;
  height: 200px;
}
.camera-network {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}
.buttons-popup {
  width: 100%;
  display: flex;
  gap: 10px;
}

/* attendees */
.attendees {
  width: 90%;
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: auto;
  padding-right: 5px;
  gap: 10px;
  color: #fff;
  position: relative;
}
.attendees::-webkit-scrollbar {
  width: 7px;
  background-color: #26235f;
  border-radius: 5px;
}
.attendees::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #ffffff;
  border-radius: 5px;
}
table.table-attendees {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  overflow: scroll !important;
  width: 60vw !important;
  position: relative;
}
.table-attendees td.cell-activity {
  background-color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.table-attendees tbody {
  background-color: #414f9c21;
  overflow: scroll;
}
.table-attendees thead {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #26235f;
  position: sticky;
}

.table-attendees th,
tr,
td {
  height: 40px !important;
  padding: 0;
  text-align: left;
  padding-left: 10px;
  border-radius: 5px;
}
.btn-primary {
  margin-left: 5%;
  margin-bottom: 30px !important;
  background-color: #bedf35;
  color: #0b0b3e;
  font-weight: 700;
  padding: 5px;
  border-radius: 5px;
}

.btn-primary-download-activity {
  border: none;
  height: 90%;
  background-color: #bedf35;
  color: #0b0b3e;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (min-width: 300px) and (max-width: 1024px) {
  .button-movile {
    width: 90% !important;
  }
  .space {
    width: 100%;
  }

  .form-grid {
    width: 100%;
    margin-left: 0;
    padding: 0;
    grid-template-columns: 1fr;
    justify-content: center;
    position: relative;
  }
  /* style schedule */
  .schedule {
    width: 100%;
  }
  .list-schedule {
    border-bottom: 1px solid #fff;
    padding: 0;
    width: 100%;
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }
  .list-schedule li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border: none;
    padding-bottom: 20px;
  }

  /* style speaker */

  .grip-speaker {
    display: grid;
    margin-left: -20px;
    grid-template-columns: 1fr;
    font-size: calc(0.5em + 1vw);
  }
  .list-speaker {
    width: 90%;
  }
  .speaker-box a {
    width: 100% !important;
    display: flex;
    justify-content: space-evenly;
  }
}
/* style schedule mobile */
.schedule-mobile {
  width: 100%;
  height: 90vh;
  overflow: scroll;
}
.list-schedule-mobile {
  color: white;
  width: calc(100%) !important;
  font-size: calc(0.8em + 0.5vw);
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
}
.list-schedule-mobile ul {
  list-style: none;
  flex-direction: column;
}

.img-name-speaker {
  display: flex;
  align-items: center;
}
.marginT {
  margin-top: 25px;
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .agendaMobile{
    height: 100vh !important;
  }
  .picture {
    width: 100%;
    border-radius: 10px;
  }
  .title-shedule-mobile {
    width: 100%;
    color: white;
    margin-top: 0;
    padding: 0;
  }
  .principal {
    width: 100%;
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
  }
  .display-video-mobile {
    background-color: #0b0b3e;
    height: calc(100vh - 100px) !important;
    width: 100%;
    color: white;
  }

  .header-screen {
    position: absolute;
    display: flex;
    justify-content: space-between;
    z-index: 80;
    width: 100%;
    top: 0;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0.507) 0%,
      rgba(16, 9, 121, 0) 80%
    );
    
    color: #fff;
    font-size: 18px;
    background-size: cover;
    background-attachment: fixed;
  }
  .title-screen-mobile{
    margin: 5px 5%;
    font-size: 1.3rem;
    font-weight: 700;
  }
  .subtitle-screen-mobile{
    margin: 0 5%;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .name-user-mobile {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header_bg);
  }
  #screen-m{
    width: 100%;
    height: calc(100vh - 100px) !important;
    position: relative;
  }
  .screen-manager {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
  }
  .screen-manager-absolute{
    position: absolute;
    width: 33%;
    height: 30vh;
    bottom: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    background-color: #0909a527;
    z-index: 999999;
  }
  .sponsor {
    width: 100%;
    height: 45vh !important;
    overflow-y: scroll !important;
    margin: 0 !important;
    padding-top: 25px;
    padding-right: 5px;
    gap: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .formRegistroDesktop{
    margin:  0 auto;
    height: auto;
    padding: 0;
  }
  .grid-gold {
    display: flex;
  }
  .grid-gold button {
    width: 100%;
    height: 150px;
  }
  .grid-silver {
    display: flex;
  }
  .grid-silver button {
    width: 100%;
    height: 150px;
  }
  .networking {
    width: 90%;
    height: 95%;
    overflow-y: scroll;
    margin: auto;
    padding-top: 25px;
    padding-right: 5px;
    gap: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .box-network {
    width: 95%;
    max-height: 250px;
    color: #fff;
    padding: 7px;
    background-color: #26235f;
    border-radius: 10px;
  }
  .schedule {
    height: 90vh !important;
    overflow-y: scroll;
  }
  .list-speaker {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .grip-speaker {
    width: 100% !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    left: 3%;
  }
  .speaker-box-c div h1 {
    font-size: 14px !important;
    line-height: 100%;
  }
  .speaker-box-c div p {
    font-size: 14px !important;
    line-height: 130%;
  }
  .speaker-box a {
    width: 95% !important;
    display: flex;
    justify-content: flex-start;
  }
  .human {
    width: 30px;
  }
  .list-agenda {
    width: 100%;
  }
  .list-agenda {
    display: none;
  }
}
