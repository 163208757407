.content-surveys {
  position: relative;
  height: calc(30vh);
  overflow-y: scroll;
}
.content-surveys::-webkit-scrollbar {
  width: 7px;
  background-color: var(--main_bg);
}
.content-surveys::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
.resultado {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-create {
  position: sticky;
  top: 0;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--social_bg);
  color: var(--header_bg);
  font-weight: 600;
}

.btn-options {
  margin-bottom: 5px;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  color: var(--header_bg);
  border: none;
}

.btn-lanzar {
  border: none;
  margin-left: 1%;
  width: 17%;
  font-size: 0.6rem;
  height: 40px;
  font-weight: 800;
  background-color: var(--social_bg);
  border-radius: 5px;
}

.btn-add {
  cursor: pointer;
  border: none;
  color: var(--social_bg);
  font-size: 1.2rem !important;
  margin-left: 1%;
  width: 15%;
  font-size: 0.6rem;
  height: 35px;
  font-weight: 800;
  background-color: transparent;
  border-radius: 5px;
}
.respuestas {
  list-style: none;
}

.barra {
  border-radius: 5px;
  padding: 5px;
  display: flex;
  height: 20px;
  margin-top: 5px;
  justify-content: space-between;
  margin-right: 10px;
  transition: width 0.5s;
  align-items: center;
}
.form-create-survey {
  flex-direction: column;
}

.form-create-survey input {
  width: 80%;
  border-radius: 5px;
  height: 30px;
  background-color: white;
}
.input-question {
  margin-top: 15px !important;
  width: 80% !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  height: 40px !important;
  border: none !important;
  border-bottom: 1px solid var(--social_bg) !important;
}
.input-question::placeholder {
  color: white;
}
.inputs-survey {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
