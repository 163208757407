.main-bg {
  margin: auto;
  margin-bottom: 50px;
}

.flex-box {
  width: 100%;
  height: 12vh !important;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
h1.tituloevento {
  color: #fff;
  font-weight: 700;
}
span.weight-normal {
  font-weight: 400;
}
.flex-box a {
  width: 35%;
}
.logo-agenda {
  width: 100%;
  text-align: center;
  padding: 25px;
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 0;
}
.margin25 {
  margin-top: 25px;
  margin-bottom: 70px;
}
.date-box {
  height: auto;
  margin-top: 40px;
  padding: 10px;
  background-color: var(--box_date_bg);
  color: #fff;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
.box {
  position: relative;
}
.date-box h2 {
  font-size: 28px;
}
.date-box p {
  font-size: 20px;
  font-weight: 500;
}
.picture {
  width: 100%;
}
.picture-register {
  width: 100%;
  height: 47vh !important;
}
.watch-circle {
  margin-right: 10px;
  width: 20px;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .popup-register{
    width: 70% !important;
  }
  .flex-box > div {
    width: 100% !important;
  }
  .title-infoevent{
    width: 100% !important;
  }
  .picture-register {
    border-radius: 10px;
  }
  .flex-box {
    margin-top: 10px;
    display: block;
    height: auto !important;
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .flex-box a {
    width: 100% !important;
    border-radius: 10px;
  }
  .margin25{
    margin-top: 0;
  }
  .logo-info{
    border-radius: 10px !important;
  }
  .logo-agenda {
    width: 93% !important;
    text-align: start;
    padding: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 25px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .date-box {
    margin-top: 5px !important;
    width: 100%;
    background-color: transparent;
    line-height: 10%;
  }
  .box {
    padding-bottom: 80px;
  }
}
