.footer {
  width: 100%;
  height: 40px;
  background-color: var(--header_bg);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
}
.formtextfooter {
  font-size: 10px;
  color: #fff;
}
.logo-footer {
  width: 100px;
  height: 100%;
}
.flex-footer {
  height: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row-reverse;
}
.footer-aling {
  width: 100%;
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.container {
  width: 92%;
}
