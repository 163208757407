@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* palette */
:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;
  --body_bg: #041433;
  --blue_light: #46b2ce;
  --light: #fff;
  --box_date_bg: #0b71af;

  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;

  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;
}
* {
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.App {
  background-color: var(--main_bg);
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  box-sizing: border-box;
}
.container {
  width: 92%;
}
body {
  scrollbar-width: revert;
  background-color: var(--main_bg);
}
body::-webkit-scrollbar {
  width: 7px;
  background-color: var(--main_bg);
}
body::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
.logo-header {
  width: 180px;
}
.width-100 {
  width: 100% !important;
  height: 100vh;
  color: #04143393;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  padding: auto;
}
.wid-100 {
  width: 70%;
  gap: 10px;
}

