.dropdown {
  width: auto;
  position: relative;
}
.title-drop{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.dropdown-content {
  position: absolute;
  padding: 0;
  z-index: 999 !important;
  border-radius: 10px;
  top: 60px;
  background-color: var(--header_bg);
  right: 10px;
  box-shadow: 1px 1px 5px 1px rgba(236, 230, 230, 0.5);
}
.dropdiwn-content ul {
  list-style: none;
}

.dropdown-content ul li {
  width: 100%;

  padding: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  margin-left: -20px;
  list-style: none;
}
