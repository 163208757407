.video-grid-auditorio-sreen {
  width: 100%;
  height: calc(100% - 40px);
}

.video-grid-auditorio-0 {
  width: 100%;
  height: auto;
}
.video-grid-auditorio-1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.video-grid-auditorio-2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.video-grid-auditorio-3 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-6 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-7 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-8 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-9 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.video-grid-auditorio-10 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-11 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-12 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-13 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.video-grid-auditorio-14 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.name-user {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header_bg);
}
.schedule-info {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.schedule-info::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(255, 255, 255, 0.15) !important;
}
.schedule-info::-webkit-scrollbar-thumb {
  width: 7px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1) !important;
}
.list-schedule-info {
  width: 100% !important;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0;
  font-size: 12px !important;
  overflow-x: hidden;
}
.list-schedule-info li {
  width: calc(100%);
  margin: 0 !important;
  display: flex;

  align-items: center;
}
.list-schedule-info li:nth-child(1) {
  width: calc(20%);
  margin: 0 !important;
  flex-direction: column;
  justify-content: center;
}
.list-schedule-info li:nth-child(2) {
  width: calc(30%);
  margin: 0 !important;
  flex-direction: column;
}
.list-schedule-info li:nth-child(2) h1 {
  width: calc(100%);

  flex-direction: column;
}
.list-schedule-info li:nth-child(3) {
  width: calc(50%);
  margin: 0 !important;
  flex-direction: column;
}
.list-schedule-info li h2 {
  width: calc(100%);
  font-size: 18px !important;
  line-height: 110%;
  margin: 0;
}
.light-popup{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 31, 177, 0.5);
  z-index: 9;
}
.popup-alert{
  padding: 10px;
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--header_bg);
  color: #fff;
  border-radius: 10px;
  margin: 15% auto;
  box-shadow: 2px 1px 2px 1px rgba(255, 255, 255, 0.74);
}
.title-popup{
  font-size: 1.2em;
  color: #fff;
  text-align: center;
}
.buttons-alert{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .video-grid-auditorio-sreen {
    width: 100%;
    height: calc(100% - 40px);
  }
  
  .video-grid-auditorio-0 {
    width: 100%;
    height: auto;
  }
  .video-grid-auditorio-1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .video-grid-auditorio-2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-4 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-5 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-6 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .video-grid-auditorio-7 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-8 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-9 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .video-grid-auditorio-10 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-11 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-12 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .video-grid-auditorio-13 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .video-grid-auditorio-14 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

