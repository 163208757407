
.marquee{
    height: 40px;
    background-color: var(--header_bg);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    padding: 0 10px;
}