.light-box{
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 99;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);

}
.box-light{
    width: 50%;
    height: 50%;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;

    border-radius: 10px;
    box-shadow: 2px 2px 3px 5px rgba(0, 0, 0, .3);
}
.box-light h1{
    color: var(--header_bg);
    text-align: center;
    font-size: calc(16px + 1vw);
}