/* palette */
:root {
  --darker_bg: rgba(0, 0, 0, 0.15);
  --dark_bg: rgba(0, 0, 0, 0.03);
  --header_bg: #0b0b3e;
  --dark_med: #212529;
  --main_bg: #26235f;
  --blue_light: #46b2ce;
  --light: #fff;
  --speakers_bg: #12133f;
  --social_color: #2b285f;
  --social_bg: #bedf35;
  --warning_bg: #ff0000;
  --warning_color: #ff7b7b;
}
.auditorium {
  width: 100%;
  display: flex;
  gap: 40px;
}
.logo-info {
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 20px 20px;
}
.logo-info img {
  height: 90%;
}
.title-infoevent {
  font-size: 1.5rem;
  color: #fff;
  line-height: 110%;
}
.hour-event {
  color: var(--blue_light);
  display: flex;
  align-items: center;
}
.sectionInfo {
  color: var(--light);
  margin: 0.5rem 0 0 1rem;
}
.sectionInfo > h2 {
  margin: 0;
}
.imageInfo {
  width: 1.5rem;
  height: 1.5rem;
}
.clock {
  width: 1.3rem;
  height: 1.3rem;
}
.subtitle-info {
  font-size: 1.2rem;
  font-weight: 500;
}
.mainInfo {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
}
.mainInfo > p {
  margin: 0;
  padding-left: 0.3rem;
}
.mainInfo > .highlight {
  color: var(--blue_light);
}
.logoSponsor {
  background-color: var(--light);
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 20%;
}
.logo {
  width: 200px;
}
.sponsor {
  width: 200px;
  margin: 0.8rem 2rem;
}
.videoWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  width: 80%;
}
.videoWrapperMobile{
  width: 100%;
  height: 100%;
}
.badgePonente {
  display: flex;
  justify-content: center;
  color: var(--light);
  background-color: var(--speakers_bg);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
}
.videoMain {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.screenVideo {
  width: 100%;
}
.screenVideo-2 {
  width: 100%;
}
.marquee {
  background-color: var(--header_bg);
  color: var(--light);
  padding: 0.5rem 1rem;
}
.demoButton {
  display: flex;
  justify-content: flex-end;
}
.resources {
  display: flex;
  justify-content: space-around;
}
.hidden {
  display: none;
}
.wrapperInfo {
  display: flex;
}

#videos {
  position: relative;
  width: 100%;
  height: 70vh;
  margin: auto;
  align-self: flex-start;
}
#videos2 {
  position: relative;
  width: 100%;
  height: 71.5vh;
  margin: auto;
  align-self: flex-start;
}
#full-screen {
  position: relative;
  width: 100%;
  height: calc(100vh - 40px);
  margin: auto;
  align-self: flex-start;
}
.vid {
  position: relative;
  background-color: rgb(11 11 62);
  height: 100%;
  width: 100%;
}
.vid-mobile {
  position: relative;
  background-color: rgb(13, 13, 90);
  width: 100%;
  height: calc(100vh - 100px) !important;
}
.display-video {
  position: relative;
  height: 76vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background-color: var(--header_bg);
}

.display-video-attendee {
  position: relative;
  height: calc(70vh + 40px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.join {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(70%);

  display: flex;
  gap: 15px;
  justify-content: center;
}
.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;
  background-color: var(--header_bg);
  width: 100%;
  position: absolute;
  bottom: 0;
}
.controls-networking {
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;

  width: 100%;
  position: absolute;
  bottom: 40px;
}
.controls-networking-screen {
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;

  width: 100%;
  position: relative;
  background-color: #0b0b3e;
  bottom: 80px;
}
.controls-networking-screen2 {
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;

  width: 100%;
  position: relative;
  background-color: #0b0b3e;
  bottom: 5px;
}
.controls-2 {
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 40px;
  background-color: var(--header_bg);
  width: 100%;
  position: absolute;
  bottom: 40px;
}
.screen {
  position: relative;
  width: auto;
}
.screen-mobile {
  position: relative;
  width: 100% !important;
  height: calc(100vh - 100px);
}
.multiple-video {
  width: 100%;
  height: 150px;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  top: 0;
  right: 0;
}
.stadistics-item-body {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.statistics-item-box {
  margin-left: 25px;
}
.message-statistics {
  color: #fff;
}
.test-camera {
  width: 100%;
  height: 100%;
  background-color: rgba(190, 11, 11, 0.03);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
}

.test-camera-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 280px;
  height: 250px;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  margin: auto;
  margin-top: 8%;
}
.video-test {
  width: 250px;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 10px !important;
  overflow: hidden;
}
.popup-mic {
  width: 300px;
  z-index: 99;
  top: 8%;
  background-color: #fff;
  position: absolute;
  border-radius: 10px;
  padding: 10px;
  left: calc(50% - 150px);
}
.btn-close {
  background-color: transparent;
  font-size: 1.2rem;
  color: #46b2ce;
  border: none;
  position: relative;
  left: 90%;
}
.items-popup-mic {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  line-height: 100%;
  color: var(--header_bg);
  margin-top: 5px;
  padding: 5px;
}
.items-popup-mic:hover {
  background-color: #46b2ce;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
}
.mic-icon {
  width: 30px !important;
  background-color: #46b2ce;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.mic-icon img {
  width: 25px !important;
}
.title-status {
  text-align: center;
  color: var(--header_bg);
}
.popup-rec {
  top: 10px;
  left: 10px;
  font-size: 12px;
  position: absolute;
  background-color: white;
  padding: 2px;
  width: 150px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transition: width 2s linear 1s !important;
}
.dinamyc-width {
  width: 25px;
}
.text-recording {
  width: 140px;
  height: 25px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: width 2s linear 1s !important;
}
.dinamyc-width-text {
  width: 0px !important;
}
.text-rec{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
color: red;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .controls {
    bottom: 35px;
  }
  .logo-info {
    height: 120px;
  }
  .logo-info img{
    width: 90%;
    height: auto !important;
  }
  .wrapperMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .videoWrapper {
    width: 100%;
  }
  .logoSponsor {
    border-radius: 0 0 0.5rem 0.5rem;
    margin: 0 1rem 0 0;
  }
  .videoMain {
    margin: 1rem 0 0;
  }
  .auditorium {
    display: flex;
    flex-direction: column;
  }
  .marquee {
    border-radius: 0.5rem;
    height: 25px !important;
  }
  .resourcesList {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
